import React from 'react';
import { createChart } from 'lightweight-charts';
import { data, data1, data2 } from './data';

export const Chart = () => {
  document.body.style.position = 'relative';

  var container = document.createElement('div');
  document.body.appendChild(container);

  var width = 1200;
  var height = 600;

  var chart = (window.tvchart = createChart(container, {
    width: width,
    height: height,
    rightPriceScale: {
      scaleMargins: {
        top: 0.35,
        bottom: 0.2,
      },
      borderVisible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    grid: {
      horzLines: {
        color: '#eee',
        visible: false,
      },
      vertLines: {
        color: '#ffffff',
      },
    },
    crosshair: {
      horzLine: {
        visible: false,
        labelVisible: false,
      },
      vertLine: {
        visible: true,
        style: 0,
        width: 2,
        color: 'rgba(32, 38, 46, 0.1)',
        labelVisible: false,
      },
    },
  }));

  var series = chart.addAreaSeries({
    topColor: 'rgba(71, 237, 28, 0.4)',
    bottomColor: 'rgba(71, 237, 28, 0.0)',
    lineColor: 'rgba(71, 237, 28, 1.0)',
    lineWidth: 1,
  });

  var series1 = chart.addAreaSeries({
    topColor: 'rgba(39, 184, 224, 0.4)',
    bottomColor: 'rgba(39, 184, 224, 0.0)',
    lineColor: 'rgba(39, 184, 224, 1.0)',
    lineWidth: 1,
  });

  var series2 = chart.addAreaSeries({
    topColor: 'rgba(150, 44, 242, 0.4)',
    bottomColor: 'rgba(150, 44, 242, 0.0)',
    lineColor: 'rgba(150, 44, 242, 1.0)',
    lineWidth: 1,
  });

  series.setData(data);
  series1.setData(data1);
  series2.setData(data2);

  function businessDayToString(businessDay) {
    return new Date(
      Date.UTC(businessDay.year, businessDay.month - 1, businessDay.day, 0, 0, 0)
    ).toLocaleDateString();
  }

  var toolTipMargin = 10;
  var priceScaleWidth = 50;
  var toolTip = document.createElement('div');
  toolTip.className = 'three-line-legend';
  container.appendChild(toolTip);
  toolTip.style.display = 'block';
  toolTip.style.left = 3 + 'px';
  toolTip.style.top = 3 + 'px';

  var toolTip1 = document.createElement('div');
  toolTip1.className = 'three-line-legend1';
  container.appendChild(toolTip1);
  toolTip1.style.display = 'block';
  toolTip1.style.left = 3 + 'px';
  toolTip1.style.top = 3 + 'px';

  var toolTip2 = document.createElement('div');
  toolTip2.className = 'three-line-legend2';
  container.appendChild(toolTip2);
  toolTip2.style.display = 'block';
  toolTip2.style.left = 3 + 'px';
  toolTip2.style.top = 3 + 'px';

  function setLastBarText() {
    var dateStr =
      data[data.length - 1].time.year +
      ' - ' +
      data[data.length - 1].time.month +
      ' - ' +
      data[data.length - 1].time.day;
    toolTip.innerHTML =
      '<div style="font-size: 24px; margin: 4px 0px; color: #47ED1C"> Data</div>' +
      '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
      data[data.length - 1].value +
      '</div>' +
      '<div>' +
      dateStr +
      '</div>';
  }

  function setLastBarText1() {
    var dateStr =
      data1[data1.length - 1].time.year +
      ' - ' +
      data1[data1.length - 1].time.month +
      ' - ' +
      data1[data1.length - 1].time.day;
    toolTip1.innerHTML =
      '<div style="font-size: 24px; margin: 4px 0px; color: #27B8E0"> Data 1</div>' +
      '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
      data1[data1.length - 1].value +
      '</div>' +
      '<div>' +
      dateStr +
      '</div>';
  }
  function setLastBarText2() {
    var dateStr =
      data2[data2.length - 1].time.year +
      ' - ' +
      data2[data2.length - 1].time.month +
      ' - ' +
      data2[data2.length - 1].time.day;
    toolTip2.innerHTML =
      '<div style="font-size: 24px; margin: 4px 0px; color: #962CF2"> Data 2</div>' +
      '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
      data2[data2.length - 1].value +
      '</div>' +
      '<div>' +
      dateStr +
      '</div>';
  }

  setLastBarText();
  setLastBarText1();
  setLastBarText2();

  chart.subscribeCrosshairMove(function (param) {
    if (
      param === undefined ||
      param.time === undefined ||
      param.point.x < 0 ||
      param.point.x > width ||
      param.point.y < 0 ||
      param.point.y > height
    ) {
      setLastBarText();
      setLastBarText1();
      setLastBarText2();
    } else {
      let dateStr = param.time.year + ' - ' + param.time.month + ' - ' + param.time.day;
      var price = param.seriesPrices.get(series);
      toolTip.innerHTML =
        '<div style="font-size: 24px; margin: 4px 0px; color: #47ED1C"> Data</div>' +
        '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
        (Math.round(price * 100) / 100).toFixed(2) +
        '</div>' +
        '<div>' +
        dateStr +
        '</div>';
      var price1 = param.seriesPrices.get(series1);
      toolTip1.innerHTML =
        '<div style="font-size: 24px; margin: 4px 0px; color: #27B8E0"> Data 1</div>' +
        '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
        (Math.round(price1 * 100) / 100).toFixed(2) +
        '</div>' +
        '<div>' +
        dateStr +
        '</div>';
      var price2 = param.seriesPrices.get(series2);
      toolTip2.innerHTML =
        '<div style="font-size: 24px; margin: 4px 0px; color: #962CF2"> Data 2</div>' +
        '<div style="font-size: 22px; margin: 4px 0px; color: #20262E">' +
        (Math.round(price2 * 100) / 100).toFixed(2) +
        '</div>' +
        '<div>' +
        dateStr +
        '</div>';
    }
  });

  return <></>;
};
