export const data = [
  { time: '2018-03-28', value: 21.0 },
  { time: '2018-03-29', value: 20.8 },
  { time: '2018-03-30', value: 19.4 },
  { time: '2018-04-02', value: 18.75 },
  { time: '2018-04-03', value: 18.75 },
  { time: '2018-04-04', value: 18.95 },
  { time: '2018-04-05', value: 16.95 },
  { time: '2018-04-06', value: 17.7 },
  { time: '2018-04-09', value: 31.0 },
  { time: '2018-04-10', value: 30.2 },
  { time: '2018-04-11', value: 31.5 },
  { time: '2018-04-12', value: 27.95 },
  { time: '2018-04-13', value: 30.15 },
  { time: '2018-04-16', value: 29.6 },
  { time: '2018-04-17', value: 27.7 },
  { time: '2018-04-18', value: 21.45 },
  { time: '2018-04-19', value: 24.05 },
  { time: '2018-04-20', value: 25.6 },
  { time: '2018-04-23', value: 26.5 },
  { time: '2018-04-24', value: 28.4 },
  { time: '2018-04-25', value: 30.55 },
  { time: '2018-04-26', value: 29.4 },
  { time: '2018-04-27', value: 30.7 },
  { time: '2018-04-30', value: 31.0 },
  { time: '2018-05-02', value: 27.7 },
  { time: '2018-05-03', value: 30.8 },
  { time: '2018-05-04', value: 33.35 },
  { time: '2018-05-07', value: 33.1 },
  { time: '2018-05-08', value: 34.6 },
  { time: '2018-05-10', value: 35.2 },
  { time: '2018-05-11', value: 37.5 },
  { time: '2018-05-14', value: 38.85 },
  { time: '2018-05-15', value: 37.0 },
  { time: '2018-05-16', value: 37.05 },
  { time: '2018-05-17', value: 37.05 },
  { time: '2018-05-18', value: 38.25 },
  { time: '2018-05-21', value: 38.8 },
  { time: '2018-05-22', value: 40.0 },
  { time: '2018-05-23', value: 42.45 },
  { time: '2018-05-24', value: 42.3 },
  { time: '2018-05-25', value: 42.8 },
  { time: '2018-05-28', value: 43.45 },
  { time: '2018-05-29', value: 43.15 },
  { time: '2018-05-30', value: 35.15 },
  { time: '2018-05-31', value: 34.2 },
  { time: '2018-06-01', value: 35.35 },
  { time: '2018-06-04', value: 37.9 },
  { time: '2018-06-05', value: 35.75 },
  { time: '2018-06-06', value: 33.7 },
  { time: '2018-06-07', value: 30.0 },
  { time: '2018-06-08', value: 31.1 },
  { time: '2018-06-11', value: 32.3 },
  { time: '2018-06-13', value: 30.95 },
  { time: '2018-06-14', value: 31.45 },
  { time: '2018-06-15', value: 34.5 },
  { time: '2018-06-18', value: 35.35 },
  { time: '2018-06-19', value: 37.0 },
  { time: '2018-06-20', value: 34.0 },
  { time: '2018-06-21', value: 34.45 },
  { time: '2018-06-22', value: 34.45 },
  { time: '2018-06-25', value: 34.25 },
  { time: '2018-06-26', value: 34.35 },
  { time: '2018-06-27', value: 33.85 },
  { time: '2018-06-28', value: 35.2 },
  { time: '2018-06-29', value: 35.2 },
  { time: '2018-07-02', value: 34.85 },
  { time: '2018-07-03', value: 31.95 },
  { time: '2018-07-04', value: 35.0 },
  { time: '2018-07-05', value: 45.8 },
  { time: '2018-07-06', value: 45.45 },
  { time: '2018-07-09', value: 46.7 },
  { time: '2018-07-10', value: 48.45 },
  { time: '2018-07-11', value: 50.7 },
  { time: '2018-07-12', value: 50.2 },
  { time: '2018-07-13', value: 51.75 },
  { time: '2018-07-16', value: 52.0 },
  { time: '2018-07-17', value: 50.75 },
  { time: '2018-07-18', value: 52.0 },
  { time: '2018-07-19', value: 54.0 },
  { time: '2018-07-20', value: 53.55 },
  { time: '2018-07-23', value: 51.2 },
  { time: '2018-07-24', value: 52.85 },
  { time: '2018-07-25', value: 53.7 },
  { time: '2018-07-26', value: 52.3 },
  { time: '2018-07-27', value: 48.8 },
  { time: '2018-07-30', value: 46.3 },
  { time: '2018-07-31', value: 45.05 },
  { time: '2018-08-01', value: 42.0 },
  { time: '2018-08-02', value: 38.0 },
  { time: '2018-08-03', value: 35.0 },
  { time: '2018-08-04', value: 34.0 },
  { time: '2018-08-05', value: 33.0 },
  { time: '2018-08-06', value: 36.0 },
  { time: '2018-08-07', value: 29.0 },
  { time: '2018-08-08', value: 28.0 },
];

export const data1 = [
  { time: '2018-03-28', value: 40.0 },
  { time: '2018-03-29', value: 40.8 },
  { time: '2018-03-30', value: 40.4 },
  { time: '2018-04-02', value: 40.75 },
  { time: '2018-04-03', value: 40.75 },
  { time: '2018-04-04', value: 40.95 },
  { time: '2018-04-05', value: 39.95 },
  { time: '2018-04-06', value: 39.7 },
  { time: '2018-04-09', value: 37.0 },
  { time: '2018-04-10', value: 37.2 },
  { time: '2018-04-11', value: 36.5 },
  { time: '2018-04-12', value: 35.95 },
  { time: '2018-04-13', value: 34.15 },
  { time: '2018-04-16', value: 37.6 },
  { time: '2018-04-17', value: 37.7 },
  { time: '2018-04-18', value: 38.45 },
  { time: '2018-04-19', value: 38.05 },
  { time: '2018-04-20', value: 38.6 },
  { time: '2018-04-23', value: 38.5 },
  { time: '2018-04-24', value: 39.4 },
  { time: '2018-04-25', value: 40.55 },
  { time: '2018-04-26', value: 40.4 },
  { time: '2018-04-27', value: 40.7 },
  { time: '2018-04-30', value: 40.0 },
  { time: '2018-05-02', value: 40.7 },
  { time: '2018-05-03', value: 40.8 },
  { time: '2018-05-04', value: 33.35 },
  { time: '2018-05-07', value: 33.1 },
  { time: '2018-05-08', value: 35.6 },
  { time: '2018-05-10', value: 36.2 },
  { time: '2018-05-11', value: 38.5 },
  { time: '2018-05-14', value: 39.85 },
  { time: '2018-05-15', value: 40.0 },
  { time: '2018-05-16', value: 41.05 },
  { time: '2018-05-17', value: 41.05 },
  { time: '2018-05-18', value: 42.25 },
  { time: '2018-05-21', value: 45.8 },
  { time: '2018-05-22', value: 47.0 },
  { time: '2018-05-23', value: 48.45 },
  { time: '2018-05-24', value: 49.3 },
  { time: '2018-05-25', value: 50.8 },
  { time: '2018-05-28', value: 50.45 },
  { time: '2018-05-29', value: 50.15 },
  { time: '2018-05-30', value: 51.15 },
  { time: '2018-05-31', value: 51.2 },
  { time: '2018-06-01', value: 51.35 },
  { time: '2018-06-04', value: 47.9 },
  { time: '2018-06-05', value: 47.75 },
  { time: '2018-06-06', value: 46.7 },
  { time: '2018-06-07', value: 45.0 },
  { time: '2018-06-08', value: 44.1 },
  { time: '2018-06-11', value: 48.3 },
  { time: '2018-06-13', value: 50.95 },
  { time: '2018-06-14', value: 50.45 },
  { time: '2018-06-15', value: 48.5 },
  { time: '2018-06-18', value: 45.35 },
  { time: '2018-06-19', value: 44.0 },
  { time: '2018-06-20', value: 42.0 },
  { time: '2018-06-21', value: 45.45 },
  { time: '2018-06-22', value: 44.45 },
  { time: '2018-06-25', value: 46.25 },
  { time: '2018-06-26', value: 45.35 },
  { time: '2018-06-27', value: 44.85 },
  { time: '2018-06-28', value: 44.2 },
  { time: '2018-06-29', value: 44.2 },
  { time: '2018-07-02', value: 46.85 },
  { time: '2018-07-03', value: 45.95 },
  { time: '2018-07-04', value: 40.0 },
  { time: '2018-07-05', value: 39.8 },
  { time: '2018-07-06', value: 38.45 },
  { time: '2018-07-09', value: 37.7 },
  { time: '2018-07-10', value: 34.45 },
  { time: '2018-07-11', value: 33.7 },
  { time: '2018-07-12', value: 32.2 },
  { time: '2018-07-13', value: 33.75 },
  { time: '2018-07-16', value: 34.0 },
  { time: '2018-07-17', value: 36.75 },
  { time: '2018-07-18', value: 37.0 },
  { time: '2018-07-19', value: 38.0 },
  { time: '2018-07-20', value: 39.55 },
  { time: '2018-07-23', value: 38.2 },
  { time: '2018-07-24', value: 39.85 },
  { time: '2018-07-25', value: 40.7 },
  { time: '2018-07-26', value: 42.3 },
  { time: '2018-07-27', value: 44.8 },
  { time: '2018-07-30', value: 46.3 },
  { time: '2018-07-31', value: 45.05 },
  { time: '2018-08-01', value: 47.0 },
  { time: '2018-08-02', value: 50.0 },
  { time: '2018-08-03', value: 51.0 },
  { time: '2018-08-04', value: 49.0 },
  { time: '2018-08-05', value: 47.0 },
  { time: '2018-08-06', value: 46.0 },
  { time: '2018-08-07', value: 45.0 },
  { time: '2018-08-08', value: 44.0 },
];

export const data2 = [
  { time: '2018-03-28', value: 50.0 },
  { time: '2018-03-29', value: 51.8 },
  { time: '2018-03-30', value: 52.4 },
  { time: '2018-04-02', value: 53.75 },
  { time: '2018-04-03', value: 54.75 },
  { time: '2018-04-04', value: 58.95 },
  { time: '2018-04-05', value: 60.95 },
  { time: '2018-04-06', value: 60.7 },
  { time: '2018-04-09', value: 65.0 },
  { time: '2018-04-10', value: 67.2 },
  { time: '2018-04-11', value: 69.5 },
  { time: '2018-04-12', value: 64.95 },
  { time: '2018-04-13', value: 61.15 },
  { time: '2018-04-16', value: 62.6 },
  { time: '2018-04-17', value: 63.7 },
  { time: '2018-04-18', value: 63.45 },
  { time: '2018-04-19', value: 65.05 },
  { time: '2018-04-20', value: 65.6 },
  { time: '2018-04-23', value: 63.5 },
  { time: '2018-04-24', value: 60.4 },
  { time: '2018-04-25', value: 60.55 },
  { time: '2018-04-26', value: 60.4 },
  { time: '2018-04-27', value: 60.7 },
  { time: '2018-04-30', value: 60.0 },
  { time: '2018-05-02', value: 53.7 },
  { time: '2018-05-03', value: 51.8 },
  { time: '2018-05-04', value: 45.35 },
  { time: '2018-05-07', value: 43.1 },
  { time: '2018-05-08', value: 55.6 },
  { time: '2018-05-10', value: 60.2 },
  { time: '2018-05-11', value: 60.5 },
  { time: '2018-05-14', value: 60.85 },
  { time: '2018-05-15', value: 60.0 },
  { time: '2018-05-16', value: 60.05 },
  { time: '2018-05-17', value: 60.05 },
  { time: '2018-05-18', value: 60.25 },
  { time: '2018-05-21', value: 60.8 },
  { time: '2018-05-22', value: 68.0 },
  { time: '2018-05-23', value: 67.45 },
  { time: '2018-05-24', value: 68.3 },
  { time: '2018-05-25', value: 69.8 },
  { time: '2018-05-28', value: 69.45 },
  { time: '2018-05-29', value: 68.15 },
  { time: '2018-05-30', value: 67.15 },
  { time: '2018-05-31', value: 66.2 },
  { time: '2018-06-01', value: 60.35 },
  { time: '2018-06-04', value: 40.9 },
  { time: '2018-06-05', value: 39.75 },
  { time: '2018-06-06', value: 33.7 },
  { time: '2018-06-07', value: 23.0 },
  { time: '2018-06-08', value: 22.1 },
  { time: '2018-06-11', value: 15.3 },
  { time: '2018-06-13', value: 20.95 },
  { time: '2018-06-14', value: 22.45 },
  { time: '2018-06-15', value: 23.5 },
  { time: '2018-06-18', value: 24.35 },
  { time: '2018-06-19', value: 26.0 },
  { time: '2018-06-20', value: 36.0 },
  { time: '2018-06-21', value: 35.45 },
  { time: '2018-06-22', value: 34.45 },
  { time: '2018-06-25', value: 40.25 },
  { time: '2018-06-26', value: 49.35 },
  { time: '2018-06-27', value: 65.85 },
  { time: '2018-06-28', value: 66.2 },
  { time: '2018-06-29', value: 60.2 },
  { time: '2018-07-02', value: 55.85 },
  { time: '2018-07-03', value: 56.95 },
  { time: '2018-07-04', value: 57.0 },
  { time: '2018-07-05', value: 59.8 },
  { time: '2018-07-06', value: 60.45 },
  { time: '2018-07-09', value: 60.7 },
  { time: '2018-07-10', value: 60.45 },
  { time: '2018-07-11', value: 60.7 },
  { time: '2018-07-12', value: 60.2 },
  { time: '2018-07-13', value: 55.75 },
  { time: '2018-07-16', value: 54.0 },
  { time: '2018-07-17', value: 55.75 },
  { time: '2018-07-18', value: 60.0 },
  { time: '2018-07-19', value: 60.0 },
  { time: '2018-07-20', value: 60.55 },
  { time: '2018-07-23', value: 60.2 },
  { time: '2018-07-24', value: 60.85 },
  { time: '2018-07-25', value: 55.7 },
  { time: '2018-07-26', value: 55.3 },
  { time: '2018-07-27', value: 56.8 },
  { time: '2018-07-30', value: 60.3 },
  { time: '2018-07-31', value: 60.05 },
  { time: '2018-08-01', value: 60.0 },
  { time: '2018-08-02', value: 59.0 },
  { time: '2018-08-03', value: 59.0 },
  { time: '2018-08-04', value: 64.0 },
  { time: '2018-08-05', value: 65.0 },
  { time: '2018-08-06', value: 65.0 },
  { time: '2018-08-07', value: 66.0 },
  { time: '2018-08-08', value: 64.0 },
];
